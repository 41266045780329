import React from 'react'
import { Heading } from '../../components';
import { FormattedMessage } from 'react-intl';
import css from './ListingPage.module.css'
import IconCollection from '../../components/IconCollection/IconCollection';

export default function SectionReturnPolicy(props) {
    const { author } = props;
    const isIndividual = author?.attributes.profile.publicData.userType === 'individual-user';
    return (
        <div className={css.sectionReturnPolicy}>
            <IconCollection icon="returnPolicyIcon" />
            <div>
                <Heading as="h2" rootClassName={css.sectionHeading}>
                    <FormattedMessage id="ListingPage.returnPolicyTitle" />
                </Heading>
                <div className={css.sectionReturnPolicyContent}>
                    {isIndividual ?
                        <FormattedMessage id="ListingPage.returnPolicyIndividualSubTitle" /> :
                        <FormattedMessage id="ListingPage.returnPolicyBusinessSubTitle" />
                    }

                    {!isIndividual &&
                        <div className={css.sectionReturnPolicyGNAD}>
                            <span>{'1. '} <FormattedMessage id="ListingPage.returnPolicyRemorse" /></span>
                            <div className={css.sectionReturnPolicyGNADList}>
                                <span>{'• '} <FormattedMessage id="ListingPage.returnPolicyMindChange" /></span>
                                <span>{'• '} <FormattedMessage id="ListingPage.returnPolicyDoesntFit" /></span>
                            </div>
                        </div>
                    }

                    <div className={css.sectionReturnPolicyGNAD}>
                        <span>{!isIndividual ? '2. ' : ''} <FormattedMessage id="ListingPage.returnPolicyGNAD" /></span>
                        <div className={css.sectionReturnPolicyGNADList}>
                            <span>{'• '} <FormattedMessage id="ListingPage.returnPolicyFaulty" /></span>
                            <span>{'• '} <FormattedMessage id="ListingPage.returnPolicyPhysicalDamage" /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

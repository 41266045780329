import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldCurrencyInput } from '../../../components';
import css from './MakeABidForm.module.css';
import { types as sdkTypes } from '../../../util/sdkLoader';
import appSettings from '../../../config/settings';
import { formatMoney } from '../../../util/currency';

export const moneySubUnitAmountAtMost = (errorMessage, maxAmount) => value => {
  if (!value || value.amount / 100 <= maxAmount) {
    return undefined;
  }
  return errorMessage;
};

const getPriceValidators = (listingMinimumPriceSubUnits, marketplaceCurrency, intl) => {
  const priceRequiredMsgId = { id: 'EditListingPricingAndStockForm.priceRequired' };
  const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
  const priceRequired = validators.required(priceRequiredMsg);

  const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
  const minPrice = formatMoney(intl, minPriceRaw);
  const priceTooLowMsgId = { id: 'MakeABidForm.priceTooLow' };
  const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
  const minPriceRequired = validators.moneySubUnitAmountAbove(
    priceTooLowMsg,
    listingMinimumPriceSubUnits
  );

  return validators.composeValidators(priceRequired, minPriceRequired)

};
const { Money } = sdkTypes;
const MakeAnOfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        handleSubmit,
        inProgress,
        intl,
        listing,
        values,
        config,
      } = fieldRenderProps;
      
      const { price, publicData } = listing.attributes;
      const { currentBid } = publicData

      // Check current bid and add logic here
      const minimumBidAmount = currentBid ? currentBid.amount : price.amount

      const priceValidators = getPriceValidators(
        minimumBidAmount,
        'GBP',
        intl,
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress || (!values && values.offerPrice);

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <h3 className={css.heading}>
            <FormattedMessage id="MakeABidForm.customOffer" />
          </h3>

          <div className={css.inputWrapper}>
            <span className={css.listPrice}>
              <FormattedMessage id="MakeABidForm.listedPrice" />
              {Number(minimumBidAmount / 100).toFixed(2)}
            </span>
          </div>
          <FieldCurrencyInput
            id="offerPrice"
            name="offerPrice"
            className={css.input}
            label={intl.formatMessage({ id: 'MakeABidForm.offerPrice' })}
            currencyConfig={appSettings.getCurrencyFormatting('GBP')}
            validate={priceValidators}
          />
          <div className={submitButtonWrapperClassName}>
            <div className={css.smallText}>
              <FormattedMessage id="MakeABidForm.Allform" />
            </div>
            <PrimaryButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              className={css.submitButton}
            >
              {values?.offerPrice ? (
                <FormattedMessage
                  id="MakeABidForm.submitButtonText"
                  values={{ price: Number(values?.offerPrice?.amount / 100).toFixed(2) }}
                />
              ) : (
                <FormattedMessage id="MakeABidForm.submitButtonTextNoPrice" />
              )}
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

MakeAnOfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

MakeAnOfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const MakeABidForm = compose(injectIntl)(MakeAnOfferFormComponent);

MakeABidForm.displayName = 'MakeABidForm';

export default MakeABidForm;

import React from 'react'
import { Heading } from '../../components';
import { FormattedMessage } from 'react-intl';
import css from './ListingPage.module.css'
import IconCollection from '../../components/IconCollection/IconCollection';

export default function SectionDispatch(props) {
    const { dispatchTime } = props
    return (
        <div className={css.sectionDispatch}>
            <IconCollection icon="dispatchIcon" />
            <div>
                <Heading as="h2" rootClassName={css.sectionHeading}>
                    <FormattedMessage id="ListingPage.estimatedDispatchTitle" />
                </Heading>
                <div className={css.sectionDispatchContent}>
                    <FormattedMessage id="ListingPage.estimatedDispatchTime" values={{ days: dispatchTime }} />
                    <FormattedMessage id="ListingPage.estimatedDispatchWarning" />
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ListingCard } from '../../components';
import { setActiveListing } from '../../containers/SearchPage/SearchPage.duck';
import css from './ListingCard.module.css';
import { Heading } from '../../components';
import { FormattedMessage } from 'react-intl';

export default function RelatedListings(props) {
    const {
    } = props

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const isMapVariant = false;

    const cardRenderSizes = isMapVariant => {
        if (isMapVariant) {
            // Panel width relative to the viewport
            const panelMediumWidth = 50;
            const panelLargeWidth = 62.5;
            return [
                '(max-width: 767px) 100vw',
                `(max-width: 1023px) ${panelMediumWidth}vw`,
                `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
                `${panelLargeWidth / 3}vw`,
            ].join(', ');
        } else {
            // Panel width relative to the viewport
            const panelMediumWidth = 50;
            const panelLargeWidth = 62.5;
            return [
                '(max-width: 549px) 100vw',
                '(max-width: 767px) 50vw',
                `(max-width: 1439px) 26vw`,
                `(max-width: 1920px) 18vw`,
                `14vw`,
            ].join(', ');
        }
    };

    const {
        sameCategoryListings
    } = state.ListingPage;

    return (
        <div>
            <Heading as="h2" rootClassName={css.sectionHeading}>
                <FormattedMessage id="ListingPage.relatedListings" />
            </Heading>
            <div className={css.listingCards}>
                {sameCategoryListings?.map(list => {
                    return (
                        <div key={list.id.uuid} style={{ width: '100%' }}>
                            <ListingCard
                                className={css.listingCard}
                                listing={list}
                                key={list.id.uuid}
                                renderSizes={cardRenderSizes(isMapVariant)}
                                setActiveListing={() => dispatch(setActiveListing(list.id))}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
